<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
            <CIcon :content="titleIcon" class="mr-2" />
        注文リスト
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :table-filter="{ label: 'フィルター' }"
          :items-per-page-select="{label: '表示件数' }"
          :hover="true" :striped="true" :border="true" :small="true" :fixed="true" :items="orderDataTable"
          :fields="tableFields"
          sorter
          pagination
      >
        <template #status="{ item }">
          <td>
            <CBadge :color="getStatusClass(item.status)">{{
                getStatusLabel(item.status)
              }}
            </CBadge>
          </td>
        </template>
        <template #action>
          <td>
            <CButton class="mx-1" color="success" size="sm" variant="outline">Detail</CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>

  </CCard>
</template>

<script>
import {mapState} from "vuex";
import {
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_DELIVERING,
  ORDER_STATUS_HOLD_ON,
  ORDER_STATUS_PAYMENT,
  orderStatusList
} from "../../utils/constants";

export default {
  name: "AdminOrderTable",

  computed: {
    ...mapState({
      orderList: state => state.order.orderList,
    }),

    orderDataTable() {
      return this.orderList.map(orderItem => {
        return {
          created_at: orderItem.created_at,
          uuid: orderItem.uuid,
          shop_name: orderItem.shop ? orderItem.shop.name : '',
          user_name: orderItem.user_name ?? '',
          price: orderItem.total_price,
          status: orderItem.status,
        }
      })
    }
  },

  data() {
    return {
      titleIcon: [
        "512 512",
        "<path fill='var(--ci-primary-color, currentColor)' d='M88,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,32a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,32Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,128Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,160a64,64,0,1,0-64-64A64.072,64.072,0,0,0,424,160Zm0-96a32,32,0,1,1-32,32A32.036,32.036,0,0,1,424,64Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,192a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,192Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,288Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M88,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,88,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,88,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,256,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,256,448Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M424,352a64,64,0,1,0,64,64A64.072,64.072,0,0,0,424,352Zm0,96a32,32,0,1,1,32-32A32.036,32.036,0,0,1,424,448Z' class='ci-primary'/>",
      ],

      loading: true,

      tableFields: [
        {
          key: 'created_at',
          label: 'Created At'
        },
        {
          key: 'uuid',
          label: 'ID',
        },
        {
          key: 'shop_name',
          label: '店舗名'
        },
        {
          key: 'user_name',
          label: '購入者'
        },
        {
          key: 'price',
          label: "価格"
        },
        {
          key: 'status',
          label: '在庫状況',
        },
        {
          key: 'action',
          label: '編集'
        }
      ],
    };
  },
  methods: {
    getStatusLabel(status) {
      let statusItem = orderStatusList.find(statusObj => statusObj.code === status);
      return statusItem ? statusItem.label : '';
    },

    getStatusClass(status) {
      if(status === ORDER_STATUS_HOLD_ON) {
        return 'danger';
      } else if(status === ORDER_STATUS_PAYMENT) {
        return 'info';
      } else if(status === ORDER_STATUS_DELIVERING) {
        return 'primary';
      } else if(status === ORDER_STATUS_DELIVERED) {
        return 'success';
      } else {
        return 'secondary';
      }
    },
  },
  mounted() {
    this.loading = true;

    this.$store.dispatch('order/getOrderList', true)
        .finally(() => {
          this.loading = false;
        });
  },
};
</script>
