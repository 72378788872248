<template>
  <b-modal
      id="order-status-edit-modal"
      :title="`Change Order Status #${currentOrderItem.uuid}`"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-header-close="true"
      @show="onModalShow"
  >
      <CForm role="form" @submit.prevent="updateOrdeStatus">
          <div class="form-group" role="group">
            <label>Order Status</label>
            <v-select
              :clearable="false"
              :options="statusOptions"
              :reduce="(statusItem) => statusItem.code"
              v-model="currentStatus"
            />
          </div>

          <div class="form-group" v-if="currentOrderItem.status === ORDER_STATUS_HOLD_ON">
            <CInput label="Delivery Fee" type="number" v-model="deliveryFee" />
          </div>
        <div
            v-if="errorMessage && errorMessage !== ''"
            class="text-center text-danger mb-2"
        >
          {{ errorMessage }}
        </div>
        <div class="text-center">
          <CButton variant="outline" color="primary" type="submit" class="mx-2">
            Save
          </CButton
          >
          <CButton
              variant="outline"
              color="dark"
              type="button"
              class="mx-2"
              @click="$bvModal.hide('order-status-edit-modal')"
          >
            Cancel
          </CButton>
        </div>
      </CForm>
    <CElementCover :opacity="0.8" v-if="loading" />
  </b-modal>
</template>

<script>
import {
  ORDER_STATUS_HOLD_ON,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DELIVERED,
  orderStatusList
} from "../../utils/constants";

export default {
  props: {
    currentOrderItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },

  components: {
  },

  computed: {
    statusOptions() {
      let statusOptionList = this.statusList.filter(statusItem => statusItem.code >= this.currentStatus);

      if(this.currentStatus === ORDER_STATUS_DELIVERED) {
        return statusOptionList.filter(statusItem => statusItem.code !== ORDER_STATUS_CANCELED);
      }

      return statusOptionList;
    }
  },

  data() {
    return {
      name: "",
      errorMessage: "",
      loading: false,

      statusList: orderStatusList,
      currentStatus: 0,
      deliveryFee: null,

      ORDER_STATUS_HOLD_ON
    };
  },

  methods: {
    onModalShow() {
      this.errorMessage = '';
      this.currentStatus = this.currentOrderItem.status
      this.deliveryFee = null
    },

    resetForm() {
      this.currentOrderItem = {};
      this.currentStatus = 0;
      this.deliveryFee = null;
    },

    updateOrdeStatus() {
      this.loading = true;
      this.errorMessage = '';

      if(this.currentOrderItem.status === this.currentStatus) {
        this.errorMessage = "Selected Status is same with current Order Status";
        this.loading = false;

        return;
      }

      if(this.currentOrderItem.status === ORDER_STATUS_HOLD_ON && this.currentStatus !== ORDER_STATUS_CANCELED && !this.deliveryFee) {
        this.errorMessage = "Delivery Fee is required";
        this.loading = false;

        return;
      }

      this.$store
          .dispatch("order/changeStatus", {
            id: this.currentOrderItem.uuid,
            status: this.currentStatus,
            deliveryFee: this.deliveryFee
          })
          .then((res) => {
            let { success } = res.data;

            if (success) {
              this.resetForm();

              this.$bvModal.hide("order-status-edit-modal");
              this.$store.dispatch("order/getOrderList");
            } else {
              this.errorMessage = "Change Order Status Failed!";
            }
          })
          .catch(() => {
            this.errorMessage = "Change Order Status Failed";
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
