<template>
  <b-modal
      id="order-detail-modal"
      :title="`Order Detail (#${currentOrderId})`"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @show="onModalShow"
      size="lg"
  >
    <b-row v-if="orderItem">
      <b-col cols="12">
        <h4>Product List</h4>

        <table class="table table-bordered">
          <thead class="bg-gray-600">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Price</th>
              <th>Amount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(detail, productIndex) in orderItem.details" :key="`order-item-${productIndex}`">
            <td>{{ detail.product.id }}</td>
            <td>{{ detail.product.name }}</td>
            <td>{{ detail.price }}</td>
            <td>{{ detail.amount }}</td>
            <td>{{ detail.total_price }}</td>
          </tr>
          </tbody>
        </table>
      </b-col>
      <b-col cols="12" md="6">
        <h4>Customer Info</h4>

        <table class="table table-bordered">
          <tr>
            <td class="bg-gray-600">
              Name
            </td>
            <td class="">
              {{ orderItem.user_info.name }}
            </td>
          </tr>
          <tr>
            <td class="bg-gray-600">
              Email
            </td>
            <td class="">
              {{ orderItem.user_info.email }}
            </td>
          </tr>
          <tr>
            <td class="bg-gray-600">
              Phone Number
            </td>
            <td class="">
              {{ orderItem.user_info.phone }}
            </td>
          </tr>
          <tr>
            <td class="bg-gray-600">
              Address
            </td>
            <td class="">
              <span>{{ fullAddress }}</span>
            </td>
          </tr>
          <tr v-if="userExpectedDeliveryTime">
            <td class="bg-gray-600">
              Customer's Expect Delivery Time
            </td>
            <td>
              <span>{{ userExpectedDeliveryTime }}</span>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" md="6">
        <h4>Summary</h4>
        <table class="table table-borderless">
          <tbody>
          <tr>
            <td>Status: </td>
            <td class="text-right">
              <CBadge :color="getStatusClass(orderItem.status)">
                {{ getStatusLabel(orderItem.status) }}
              </CBadge>
            </td>
          </tr>
          <tr>
            <td>SubTotal: </td>
            <td class="text-right"><strong>{{ formatCurrency(orderItem.total_price) }}</strong></td>
          </tr>
          <tr>
            <td>Delivery Fee: </td>
            <td class="text-right"><strong>{{ formatCurrency(deliveryFee) }}</strong></td>
          </tr>
          <tr>
            <td colspan="2">
              <hr>
            </td>
          </tr>
          <tr>
            <td>Total: </td>
            <td class="text-right"><strong>{{ formatCurrency(deliveryFee + orderItem.total_price) }}</strong></td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>

    <CElementCover :opacity="0.8" v-if="loading" />
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import { formatPrice } from "../../utils/helpers";
import {
  expectedDelieryTimeList,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_DELIVERING,
  ORDER_STATUS_HOLD_ON,
  ORDER_STATUS_PAYMENT,
  orderStatusList
} from "../../utils/constants";

export default {
  name: "OrderDetail",
  props: {
    currentOrderId: {
      type: String,
      required: true,
    }
  },

  computed: {
    ...mapState({
      orderItem: state => state.order.orderItem,
      addressItem: state => state.location.addressItem,
    }),

    fullAddress() {
      let finalAddress = '';

      if(this.addressItem) {
        finalAddress += this.addressItem.address;

        if(this.addressItem.ward) {
          finalAddress += ', ' + this.addressItem.ward;
        }

        if(this.addressItem.district) {
          finalAddress += ', ' + this.addressItem.district;
        }

        if(this.addressItem.city) {
          finalAddress += ', ' + this.addressItem.city;
        }
      }

      return finalAddress;
    },

    userExpectedDeliveryTime() {
      if(this.orderItem) {
        console.log(this.orderItem);
        let expectedItem = expectedDelieryTimeList.find(item => item.code === this.orderItem.user_expected_delivery_time);
        console.log(expectedItem);

        return expectedItem ? expectedItem.label : null
      }
      return null;
    },

    deliveryFee() {
      return this.orderItem && this.orderItem.delivery_fee ? parseInt(this.orderItem.delivery_fee) : 0;
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    onModalShow() {
      this.loading = true;

      this.$store.dispatch('order/findOrder', this.currentOrderId)
          .then((res) => {
            let { success, data } = res.data;

            if(success) {
              return this.$store.dispatch('location/getAddressInfo', data.address_id)
            }
          })
        .finally(() => {
          this.loading = false;
        })
    },

    formatCurrency(price) {
      return formatPrice(price);
    },

    getStatusLabel(status) {
      let statusItem = orderStatusList.find(statusObj => statusObj.code === status);
      return statusItem ? statusItem.label : '';
    },

    getStatusClass(status) {
      if (status === ORDER_STATUS_HOLD_ON) {
        return 'danger';
      } else if (status === ORDER_STATUS_PAYMENT) {
        return 'info';
      } else if (status === ORDER_STATUS_DELIVERING) {
        return 'primary';
      } else if (status === ORDER_STATUS_DELIVERED) {
        return 'success';
      } else {
        return 'secondary';
      }
    },
  }
}
</script>

<style scoped>

</style>