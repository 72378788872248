<template>
  <div>
    <CRow ref="orderSection">
      <CCol col="12" v-if="user">
        <AdminOrderTable v-if="user.role_id === USER_ROLE_ADMIN" />
        <OrderTable v-else/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {USER_ROLE_ADMIN} from "../../utils/constants";
import AdminOrderTable from "../../components/orders/AdminOrderTable";
import OrderTable from "../../components/orders/OrdersTable";

export default {
  name: 'OrderList',

  components: {
    OrderTable,
    AdminOrderTable,
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },

  data() {
    return {
      USER_ROLE_ADMIN,
    }
  },

  metaInfo: {
    title: 'Orders Management'
  }
};
</script>
